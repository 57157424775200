<template>
  <ClientOnly v-if="usefulLabels.length ">
    <div 
      ref="realtimeType"
      class="realtime-type"
      :class="{
        'is-black-friday': blackFridayNavBg,
      }"
      :style="styleConfig"
    >
      <slot name="left"></slot>
      <div :class="['realtime-type__swiper-container',{'show-element': showRealtimeTypeLabel}]">
        <div class="realtime-type-swiper">
          <div
            ref="realtimeLabelSwiper"
            class="label-with-swiper"
            :class="{ 'change-element': showRealtimeTypeLabel }"
            :style="{'animation': animationStyle, 'animation-play-state': animationStyle ? stopBehaviorAnimation ? 'paused' : 'running' : ''}"
          >
            <div
              v-for="(item, index) in usefulLabels"
              :key="index"
              v-expose="index == usefulLabels.length - 1 ? {} : getAnalysis(item)"
              :index="index"
              class="swiper-content"
            >
              <img
                :src="cutImg(item.icon)"
                :width="16"
                :height="16"
              />
              <span 
                class="label-text"
                v-html="item.tagName"
              >
              </span>
            </div>
          </div>
        </div>
      </div>
      <slot name="right"></slot>
    </div>
  </ClientOnly>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive'
import { defineComponent, nextTick } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { initRealTimeScrollFn } from './utils.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// import { buildKeyFramesAndReturnCSSInfo } from 'public/src/pages/cart_v2/utils/index.js'
import { buildKeyFramesCSS } from 'public/src/pages/cart_v2/utils/buildKeyFramesCSS.js'
import { useCutImg } from 'public/src/pages/cart_v2/hooks/useCutImg.js'

const isElementObstructed = (el) => {
  const rect = el.getBoundingClientRect()
  const x = rect.left + rect.width / 2
  const y = rect.top + rect.height / 2
  const topElement = document.elementFromPoint(x, y)
  return topElement !== el && !el.contains(topElement)
}

export default defineComponent({
  name: 'RealtimeType',
  directives: {
    expose,
  },
  components: {
    ClientOnly,
  },
  props: {
    labels: {
      type: Array,
      default: () => ([])
    },
    eleInfo: {
      type: Object,
      default() {
        return {
          headerEle: '',
          scrollEle: '',
        }
      }
    },
    titleNavStyleFromCcc: {
      type: Object,
      default() {
        return null
      }
    }
  },
  setup() {
    const { cutImg } = useCutImg()
    return {
      cutImg
    }
  },
  data() {
    return {
      exposeIds: [],
      realtimeAnimation: null,
      showRealtimeTypeLabel: false,

      targetEle: null,
      targetEleScrollFn: null,
      stopBehaviorAnimation: false,
      buildAnimationStyle: ''
    }
  },
  computed: {
    // 实际展示的标签
    usefulLabels() {
      let usefulLabelList =  this.labels.filter((item) => item.actionDataTagShow == 1 && item.hasAvailableTag == 1) || []
      return usefulLabelList.length > 1 ? [...usefulLabelList, usefulLabelList[0]] : usefulLabelList
    },
    blackFridayNavBg() {
      return this.titleNavStyleFromCcc?.bgImage?.src || ''
    },
    styleConfig() {
      return {
        '--nav-bg': this.blackFridayNavBg ? `url(${this.blackFridayNavBg})` : '',
        '--color': this.titleNavStyleFromCcc?.fontColor || '',
        '--nav-bar-color': this.titleNavStyleFromCcc?.fontColor || '',
      }
    },
    animationStyle() {
      return this.usefulLabels.length > 1 ? this.buildAnimationStyle : ''
    }
  },
  watch: {
    'usefulLabels.length': {
      handler(n, o) {
        if (n > 0) {
          if(n !== o){
            this.loadAnimation()
          }
        } else {
          this.loadAnimation()
        }
      },
    }
  },
  mounted() {
    nextTick(() => {
      this.initScroll()
      this.loadAnimation()
    })
  },
  beforeUnmount() {
    this.leave()
  },
  activated() {
    this.addScroll()
  },  
  deactivated() {
    this.leave()
  },
  methods: {
    // 滚动的交互处理
    initScroll() {
      if (!this.usefulLabels.length) return
      const { scrollEle, headerEle } = this.eleInfo
      const headerElDom = document.querySelector(headerEle)
      if (!headerElDom) return
      
      let pageName = window.SaPageInfo?.page_name || ''
      let scrollEleDom = document.querySelector(scrollEle)
      const canScroll = scrollEle 
        ? scrollEleDom?.scrollHeight > scrollEleDom?.clientHeight 
        : document.documentElement.scrollHeight > window.innerHeight

      if (!canScroll && pageName !== 'page_cart') {
        this.$refs.realtimeType.style.height = '1.1733rem'
        this.$refs.realtimeType.style.opacity = 1
        this.showRealtimeType(true)
      }
      const fn = initRealTimeScrollFn(headerElDom, this.$refs.realtimeType, this.showRealtimeType)
      
      this.targetEle = scrollEleDom || document
      this.targetEleScrollFn = (e) => {
        if (!this.usefulLabels.length) return
        if (this.targetEle === document) {
          if (isElementObstructed(headerElDom) && isElementObstructed(this.$refs.realtimeType)) return // 都被遮挡则不处理后续动画了
        }
        fn(e)
      }

      this.addScroll()
    },
    addScroll() {
      this.targetEle?.addEventListener?.('scroll', this.targetEleScrollFn, true)
    },
    removeScroll() {
      this.targetEle?.removeEventListener?.('scroll', this.targetEleScrollFn, true)

    },
    // 埋点处理
    onExpose({ item }) {
      if (!item?.tagId || this.exposeIds.includes(item?.tagId)) return
      this.exposeIds.push(item.tagId)
    },
    leave() {
      // 配置了abt则用实际配置的标签，否则使用全部标签
      const reportArr = !!this.usefulLabels.length ? this.usefulLabels : this.labels
      if (!reportArr.length) return
      daEventCenter.triggerNotice({
        daId: '1-8-1-41',
        extraData: {
          userbehavior_tips: Array.from(new Set(reportArr.map((v) => v.tagId))).join(','),
          userbehavior_tips_show: this.exposeIds.join(','),
        }
      })
      this.reset()
      this.removeScroll()
    },

    reset() {
      this.exposeIds = []
    },
    showRealtimeType(show) {
      this.showRealtimeTypeLabel = show
      if (show) {
        this.stopBehaviorAnimation = false
        // this.realtimeAnimation?.play()
      } else {
        this.stopBehaviorAnimation = true
        // this.realtimeAnimation?.pause()
      }
    },
    getAnalysis(item) {
      return {
        id: '1-8-1-10000000', // 假id
        prefix: 'realtime_behavior_lable_prefix', // 用于重置埋点
        callback: () => {
          this.onExpose({ item })
        }
      }
    },
    loadAnimation(){
      if (this.buildAnimationStyle) {
        // this.realtimeAnimation?.cancel?.()
        // this.realtimeAnimation = null
        this.buildAnimationStyle = ''
      }
      if(this.usefulLabels.length < 2) {
        this.buildAnimationStyle = ''
        return
      }
      buildKeyFramesCSS(false, (this.usefulLabels.length - 1), .2, 1.3).then((res) => {
        this.buildAnimationStyle = res
      })
      
      // if(animateList.length) {
      //   let options = {
      //     duration: (this.usefulLabels.length - 1) * 1.5 * 1000,
      //     iterations: Infinity,
      //     easing: 'linear',
      //     fill: 'forwards',
      //     delay: 100
      //   }
      //   this.realtimeAnimation = this.$refs.realtimeLabelSwiper?.animate(animateList, options)
      //   !this.showRealtimeTypeLabel && this.realtimeAnimation?.pause()
      // }
    }
  },
})
</script>

<style lang="less" scoped>
.realtime-type {
  position: relative;
  z-index: 9;
  height: 0px;
  opacity: 0;
  padding: 0 12/37.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
  background-color: #fff;
  background-image: var(--nav-bg);
  background-size: 100% auto;
  background-repeat: no-repeat;

}
.realtime-type__swiper-container{
  border-radius: 12px;
  padding: 4/37.5rem 8/37.5rem;
  background: @sui_color_micro_emphasis_bg; 
  box-sizing: border-box;
  display: none;
  &.show-element{
    display: block;
  }
}
.realtime-type-swiper {
  width: auto;
  min-width: 120/37.5rem;
  height: 18/37.5rem;
  overflow: hidden;
  contain: layout;
}
.label-with-swiper {
  scroll-snap-type: y mandatory;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  display: inline-flex;
  flex-direction: column;
  &.change-element{
    will-change: transform;
  }
  .swiper-content {
    overflow: hidden;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    // height: 100%;
    .label-text {
      margin-left: 4/37.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* rtl:begin:ignore */
      direction: ltr;
    }
  }
}
.is-black-friday {
  .realtime-type__swiper-container {
    background: rgba(255, 255, 255, 0.20);
    .label-text {
      color: var(--color, #fff);
    }
  }
  :deep(font) {
    color: var(--color, #fff);;
  }
}
</style>
