const appendStyleLink = (cssStr) => {
  return new Promise((resolve, reject) => {
    const blob = new Blob([cssStr], { type: 'text/css' })

    const url = URL.createObjectURL(blob)
  
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = url
    link.onload = function () {
      URL.revokeObjectURL(url)
      resolve()
    }
    link.onerror = function () {
      URL.revokeObjectURL(url)
      reject()
    }
  
    document.head.appendChild(link)
  })
}

/**
 * 生成keyframes动画
 * @param {Boolean} isHorizontal 是否水平滚动
 * @param {Number} count 滚动次数(列表真实长度，使用此方法时需要在列表末尾加上一个第一个元素)
 * @param {Number} scrollTime 滚动时间
 * @param {Number} displayTime 静止展示时间
 * @param {Object} options 自定义动画配置 如果需要自定义options必须传入name且唯一
 * 默认配置
 * options = {
 *  name: 'dynamic-slide',
    iterations: infinity,
    easing: 'ease-in-out',
    fill: 'forwards',
    delay: 0,
  }
 */
export const buildKeyFramesCSS = async (isHorizontal, count, scrollTime, displayTime, options = null) => {
  if (count < 2) return ''
  if(options && !options.name) {
    console.error('[error] options.name is required')
    return
  }
  let animationName = `${options?.name || 'dynamic-slide'}-${count}`
  // if(animateList[animationName]) {
  //   return animateList[animationName]
  // }
  // let animateList = []
  const translate = isHorizontal ? 'translateX' : 'translateY'
  const sumTime = (displayTime + scrollTime) * count // 总动画时长
  let sumPercentage = 0
  let translateValue = 0
  let cssStr = `@keyframes ${animationName} { 0%{transform:${translate}(0%);}`
  // animateList.push({ transform: `${translate}(0%)` })
  for (let index = 0; index < count; index++) {
    // 静止展示动画区段
    sumPercentage += displayTime / sumTime
    cssStr += `${(sumPercentage * 100).toFixed(2)}%{transform:${translate}(-${(translateValue * 100).toFixed(2)}%);}`
    // animateList.push({ transform: `${translate}(-${(translateValue * 100).toFixed(2)}%)`, offset: sumPercentage.toFixed(4) })

    // 滚动动画区段
    sumPercentage += scrollTime / sumTime
    translateValue = (index + 1) / (count + 1)
    cssStr += `${(sumPercentage * 100).toFixed(2)}%{transform:${translate}(-${(translateValue * 100).toFixed(2)}%);}`
    // animateList.push({ transform: `${translate}(-${(translateValue * 100).toFixed(2)}%)`, offset: sumPercentage.toFixed(4) })
  }
  cssStr += '}'
  await appendStyleLink(cssStr)
  return `${animationName} ${sumTime}s ${options?.iterations || 'infinite'} ${options?.delay || 0}s ${options?.easing || 'ease-in-out'} normal ${options?.fill || 'forwards'}`
}
